export function getAddressStringified(address: any) {
  if (address) {
    const {
      houseNumber = "",
      streetDirection = "",
      streetName = "",
      city = "",
      state = "",
      zipCode = "",
      county = ""
    } = address;
    return `${houseNumber} ${streetDirection} ${streetName}, ${city} ${state}, ${zipCode}, ${county}`;
  }
  return "";
}
